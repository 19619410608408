// import { sha512 } from 'js-sha512';

import type { NetworkSettingsTypeEnum } from '@/enums';
// import { uint8ToBase64 } from '@/helpers';
import axios from '@/services/axios';
import type {
  ResponseErrorModel,
  ResponseNetworkModel,
  ResponseNetworkFullSettingsModel,
  ResponseNetworkResourcesModel,
  NetworkLocateModel,
  ResponseNetworkLocateModel,
  NetworkUploadLogoResponseModel,
  NetworkSettingsModel,
  NetworkMobileAppsModel,
  NetworkBrandingModel,
  ResponseNetworkMobileAppsModel,
  ResponseNetworkBrandingModel,
  ResponseNetworkDesignModel,
  NetworkDesignModel,
  NetworkUploadFaviconResponseModel,
  NetworkEmailFooterModel,
  ResponseNetworkEmailFooterModel,
} from '@/types';

export class NetworksApiService {
  async getLocate(
    uri: string,
    clientSecret: string
  ): Promise<NetworkLocateModel | undefined> {
    let settings: NetworkLocateModel | undefined = undefined;

    if (!uri || !clientSecret) {
      console.error('uri and clientSecret are required');
      return settings;
    }

    const api = import.meta.env.VITE_APP_URL_API;
    const env = import.meta.env.VITE_APP_ENV; // qa / beta / prod
    /*
      TODO: use sha512 for new back
      const url = `${uri}${api}/networks/locate/?clientSecret=${uint8ToBase64(sha512.array(clientSecret))}&env=${env}`;
    */
    const url = `${uri}${api}/networks/locate/?clientSecret=${clientSecret}&env=${env}`;
    /*
      TODO: Add version to the request
      const version = import.meta.env.VITE_APP_API;
      const url = `${uri}${api}/networks/locate/?clientSecret=${uint8ToBase64(sha512.array(clientSecret))}&env=${env}&version=${version}`;
    */

    await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (response: Response) => {
        if (response.ok) {
          await response
            .json()
            .then((responseToken: ResponseNetworkLocateModel) => {
              settings = responseToken.data;
            });
        } else {
          console.log(response.status);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });

    return settings;
  }

  async settings(
    clientSecret: string
  ): Promise<ResponseNetworkFullSettingsModel | ResponseErrorModel> {
    return axios.get(`/networks/settings/?clientSecret=${clientSecret}`);
  }

  async current(
    clientSecret: string
  ): Promise<ResponseNetworkModel | ResponseErrorModel> {
    return axios.get(`/networks/current/?clientSecret=${clientSecret}`);
  }

  async getResources(
    clientSecret: string
  ): Promise<ResponseNetworkResourcesModel | ResponseErrorModel> {
    return axios.get(
      `/networks/resources?clientSecret=${clientSecret}&mobile=true`
    );
  }

  async uploadLogo(
    key: string,
    type: string
  ): Promise<NetworkUploadLogoResponseModel | ResponseErrorModel> {
    return axios.post('/networks/logo', {
      key: key,
      type: type,
    });
  }

  async uploadFavicon(
    key: string,
    type: string
  ): Promise<NetworkUploadFaviconResponseModel | ResponseErrorModel> {
    return axios.post('/networks/favicon', {
      key: key,
      type: type,
    });
  }

  async saveNetworkSettings(
    data:
      | NetworkSettingsModel
      | NetworkBrandingModel
      | NetworkMobileAppsModel
      | NetworkDesignModel,
    type: NetworkSettingsTypeEnum
  ): Promise<ResponseNetworkFullSettingsModel | ResponseErrorModel> {
    return axios.post(`/networks/settings/${type}`, data);
  }

  /* async currentNetworkSettings(): Promise<
    ResponseNetworkFullSettingsModel | ResponseErrorModel
  > {
    return axios.get(`/networks/settings/all`);
  } */

  async networkSettings(): Promise<
    ResponseNetworkFullSettingsModel | ResponseErrorModel
  > {
    return axios.get(`/networks/settings/all`);
  }

  async mobileAppsSettings(): Promise<
    ResponseNetworkMobileAppsModel | ResponseErrorModel
  > {
    return axios.get(`/networks/settings/mobileapps`);
  }

  async brandingSettings(): Promise<
    ResponseNetworkBrandingModel | ResponseErrorModel
  > {
    return axios.get(`/networks/settings/branding`);
  }

  async designSettings(): Promise<
    ResponseNetworkDesignModel | ResponseErrorModel
  > {
    return axios.get(`/networks/settings/style`);
  }

  async emailFooterSettings(): Promise<
    ResponseNetworkEmailFooterModel | ResponseErrorModel
  > {
    return axios.get(`/networks/settings/emailFooter`);
  }

  async saveEmailFooter(
    body: NetworkEmailFooterModel['emailFooter']
  ): Promise<ResponseNetworkEmailFooterModel | ResponseErrorModel> {
    return axios.post(`/networks/settings/emailFooter`, { body });
  }
}
