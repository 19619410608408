import { Browser } from '@capacitor/browser';
import type { Route } from '@sentry/vue/types/router';
import useVuelidate from '@vuelidate/core';
import type { ComputedRef } from 'vue';
import { computed, reactive } from 'vue';

import { componentCampusModal } from './modalComponents';
import { useAiAssistant } from './useAiAssistantHelper';
import { useTaskManagement } from './useTaskManagementHelper';

import {
  AppIconsEnum,
  AppMenuEnum,
  CompanyMenuEnum,
  UserRoleEnum,
} from '@/enums';
import { isAnyMobile } from '@/helpers';
import { extentedUrl } from '@/helpers/i18nValidators';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useAppStore, useProjectsStore, useUserStore } from '@/store';
import type { AppMenuItem, ProjectEntity, CompaniesListType } from '@/types';

export type IUseMenu = {
  companiesList: ComputedRef<CompaniesListType>;
  /**
   * @description Returns the menu items for the header.
   */
  getHeaderMenu(): AppMenuItem[];
  /**
   * @description Returns the menu items (enabled / not hidden / sorted) for the footer.
   */
  getRightMenu(): AppMenuItem[];
  /**
   * @description Returns the menu items for the footer.
   * - If isMDWidth is true (innerWidth of the screen is >= 768px), the maxItemsInHeaderMenu is used.
   * - If isMDWidth is false (innerWidth of the screen is < 768px), the maxItemsInBottomMenu is used.
   * - If the number of items in the menu is greater than:
   *  - the maxItemsInBottomMenu (for isMDWidth = false)
   *  - maxItemsInHeaderMenu (for isMDWidth = true),
   * - ...slicing the full menu items array and adding the "More" item with submenu.
   * @see src/helpers/useLayoutHelper.ts
   * @todo Different menu layout/order for mobile
   */
  getFooterMenu(): AppMenuItem[];
  /**
   * @description Checks if the Campus is enabled for the current company.
   */
  isCampusEnabled(): boolean;
  /**
   * @description Checks if the Quick Search is enabled for the current company.
   */
  isQuickSearchEnabled(): boolean;
  /**
   * @description Get the Campus link for the current company.
   */
  getCampusLink(): string;
  /**
   * @description Opens the Campus modal or the Campus link in the browser.
   */
  openCampusModal(): Promise<void>;
  /**
   * @description Checks if the menu item is active.
   * @todo Refactor
   */
  checkForActive(element: any, route: Route): boolean;
  /**
   * @description Validates the custom link.
   * @todo Refactor
   */
  validateCustomLink(href: string): Promise<boolean>;
};

export const useMenu = (): IUseMenu => {
  //* Helpers
  const { t } = useI18n();
  const aiAssistantHelper = useAiAssistant();
  const taskManagementHelper = useTaskManagement();

  //* Store
  const appStore = useAppStore();
  const userStore = useUserStore();
  const projectsStore = useProjectsStore();

  //* Constants
  const maxItemsInBottomMenu = 5;
  /*
    TODO: When the admin panel will allow to manage the menu items, use it
    const isCustomMenuManagedByAdminPanel = false;
  */

  //* Computed
  const homePage: ComputedRef<any> = computed(() => appStore.homePage);
  const isMDWidth: ComputedRef<boolean> = computed(() => appStore.isMDWidth);
  const maxItemsInHeaderMenu: ComputedRef<number> = computed(
    () => appStore.maxItemsInHeaderMenu
  );
  const currentUserIsKEDummy: ComputedRef<boolean> = computed(
    () => userStore.current?.id == import.meta.env.VITE_KLINIKUM_ERDING_ID
  );
  const currentCompanyId: ComputedRef<string> = computed(
    () => appStore.companyRowId
  );
  const currentProject: ComputedRef<ProjectEntity> = computed(
    () => projectsStore.getCurrentProject
  );
  const currentUserId: ComputedRef<number> = computed(
    () => userStore.current?.id ?? 0
  );
  const currentUserRoleId: ComputedRef<number> = computed(
    () => userStore.current?.roleId ?? 0
  );
  const aiAssistantEnabled: ComputedRef<boolean> = computed(() =>
    aiAssistantHelper.getAccessToAiAssistant()
  );
  const taskManagementEnabled: ComputedRef<boolean> = computed(() =>
    taskManagementHelper.getAccessToTaskManagement()
  );

  const companiesList: ComputedRef<CompaniesListType> = computed(() => {
    if (!import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_MENU_ITEMS) {
      console.warn(
        'The env variable VITE_COMPANIES_LIST_WITH_DISABLED_MENU_ITEMS is not defined'
      );
      return {};
    }

    try {
      return JSON.parse(
        import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_MENU_ITEMS
      );
    } catch (error) {
      console.error(
        'Error while parsing the VITE_COMPANIES_LIST_WITH_DISABLED_MENU_ITEMS env variable:',
        error
      );
      return {};
    }
  });

  /**
   * @description Get the default menu items with NO customizations / filtering applied.
   */
  const _getDefaultMenuItems = (): AppMenuItem[] => {
    return [
      {
        order: 0,
        name: AppMenuEnum.HomePage,
        title: t('appBottomMenu.home'),
        icon: AppIconsEnum.Home,
        enabled: true,
        submenu: null,
        hidden: false,
        link: homePage.value,
      },
      {
        order: 1,
        name: AppMenuEnum.Feed,
        title: t('appMenu.feed'),
        // icon: AppIconsEnum.OldFeed,
        icon: AppIconsEnum.Feed,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.FEED },
      },
      {
        order: 2,
        name: AppMenuEnum.Messenger,
        title: t('appMenu.messenger'),
        icon: AppIconsEnum.Comments,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.MESSENGER_ACTIVE },
      },
      {
        order: 5,
        name: AppMenuEnum.People,
        title: t('appMenu.people'),
        icon: AppIconsEnum.User,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.USERS },
      },
      {
        order: 6,
        name: AppMenuEnum.Groups,
        title: t('appMenu.groups'),
        icon: AppIconsEnum.Users,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.GROUPS },
      },
      {
        order: 7,
        name: AppMenuEnum.Search,
        title: t('appMenu.search'),
        icon: AppIconsEnum.SearchSm,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.SEARCH },
      },
      {
        order: 8,
        name: AppMenuEnum.AiAssistant,
        title: t('appMenu.aiAssistant'),
        icon: AppIconsEnum.Rocket,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.AI_ASSISTANT },
      },
      {
        order: 9,
        name: AppMenuEnum.Projects,
        title: t('appMenu.projects'),
        icon: AppIconsEnum.Projects,
        enabled: true,
        hidden: false,
        link: { name: ROUTES_NAME.PROJECTS },
        submenu: [
          {
            order: 9,
            name: AppMenuEnum.Projects,
            title: t('taskManagement.projects.myProjects'),
            icon: AppIconsEnum.Projects,
            enabled: true,
            submenu: null,
            hidden: false,
            link: { name: ROUTES_NAME.PROJECTS },
          },
          {
            order: 9,
            name: AppMenuEnum.Tasks,
            title: t('taskManagement.tasks.myTasks'),
            icon: AppIconsEnum.Issues,
            enabled: true,
            submenu: null,
            hidden: false,
            link: { name: ROUTES_NAME.TASKS },
          },
          {
            order: 9,
            name: AppMenuEnum.Milestones,
            title: t('taskManagement.milestones.all'),
            icon: AppIconsEnum.Milestones,
            enabled: true,
            submenu: null,
            hidden: false,
            link: {
              name: ROUTES_NAME.MILESTONES,
              params: { projectId: currentProject.value.id },
            },
          },
          /*
          {
            order: 9,
            name: AppMenuEnum.ProjectsStatistics,
            title: t('statistics.title'),
            icon: AppIconsEnum.Projects,
            enabled: true,
            submenu: null,
            hidden: false,
            link: { name: ROUTES_NAME.PROJECTS_STATISTICS },
          },
          */
        ],
      },
      {
        order: 10,
        name: AppMenuEnum.Docs,
        title: t('appMenu.docs'),
        icon: AppIconsEnum.Folder,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.DOCS },
      },
      /* //TODO
      {
        order: 10,
        name: AppMenuEnum.Wiki,
        title: t('appMenu.docs'),
        icon: AppIconsEnum.DocText,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.WIKIS },
      },
      */
      {
        order: 11,
        name: AppMenuEnum.Calendar,
        title: t('appMenu.calendar'),
        icon: AppIconsEnum.Calendar,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.CALENDAR },
      },
      {
        order: 12,
        name: AppMenuEnum.Pages,
        title: t('appMenu.pages'),
        icon: AppIconsEnum.Documents,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.PAGES },
      },
      {
        order: 13,
        name: AppMenuEnum.Topics,
        title: t('appMenu.topics'),
        icon: AppIconsEnum.Labels,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.TOPICS },
      },
      {
        order: 14,
        name: AppMenuEnum.Ideas,
        title: t('appMenu.ideas'),
        icon: AppIconsEnum.Bulb,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.IDEAS },
      },
      {
        order: 15,
        name: AppMenuEnum.UsageRules,
        title: t('appMenu.usageRules'),
        icon: AppIconsEnum.List,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.USAGE_RULES },
      },
      {
        order: 16,
        name: AppMenuEnum.Login,
        title: t('appMenu.login'),
        icon: AppIconsEnum.Login,
        enabled: true,
        submenu: null,
        hidden: false,
        link: null,
      },
      {
        order: 17,
        name: AppMenuEnum.CustomLink,
        title: '',
        icon: AppIconsEnum.Unlink,
        enabled: true,
        submenu: null,
        hidden: false,
        link: null,
        href: '',
      },
      {
        order: 18,
        name: AppMenuEnum.SecondCustomLink,
        title: '',
        icon: AppIconsEnum.Unlink,
        enabled: true,
        submenu: null,
        hidden: false,
        link: null,
        href: '',
      },
      {
        order: 19,
        name: AppMenuEnum.CustomPage,
        title: '',
        icon: AppIconsEnum.Documents,
        enabled: true,
        submenu: null,
        hidden: false,
        link: null,
        href: '',
      },
      {
        order: 20,
        name: AppMenuEnum.Icons,
        title: t('appMenu.icons'),
        icon: AppIconsEnum.Icons,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.UI_KIT_ICONS },
      },
      {
        order: 21,
        name: AppMenuEnum.Campus,
        title: t('campus.title'),
        icon: AppIconsEnum.Campus,
        enabled: true,
        submenu: null,
        hidden: !isCampusEnabled(),
      },
    ];
  };

  //* Private
  /**
   * @description Creates a new menu item with chosen properties.
   * @param {Partial<AppMenuItem>} item - The properties to apply to the new menu item.
   * @returns The created menu item.
   */
  const _createMenuItem = (item: Partial<AppMenuItem>): AppMenuItem => {
    const defaultItem = _getDefaultMenuItems().find(
      (menuItem) => menuItem.name === item.name
    );

    return {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...defaultItem!, //TODO: Fix later
      ...item,
    };
  };

  /**
   * @description Get the list of menu items based on provided array of AppMenuEnum.
   * @example _filterMenuItems([AppMenuEnum.HomePage, AppMenuEnum.Feed])
   */
  const _filterMenuItems = (items: AppMenuEnum[]): AppMenuItem[] => {
    return _getDefaultMenuItems().filter((menuItem) =>
      items.includes(menuItem.name)
    );
  };

  const _handleHomePageMenuItem = (item: AppMenuItem): AppMenuItem => {
    item.enabled = item.link?.name !== ROUTES_NAME.FEED;
    return item;
  };

  const _handleSearchMenuItem = (item: AppMenuItem): AppMenuItem => {
    item.enabled = !currentUserIsKEDummy.value && !isQuickSearchEnabled();
    return item;
  };

  const _handleAiAssistantMenuItem = (item: AppMenuItem): AppMenuItem => {
    item.enabled = aiAssistantEnabled.value;
    return item;
  };

  const _handleProjectsMenuItem = (item: AppMenuItem): AppMenuItem => {
    item.enabled = taskManagementEnabled.value;
    item.submenu =
      item.submenu?.map((subItem) => {
        if (subItem.name === AppMenuEnum.Milestones) {
          subItem.enabled = currentProject.value.id > 0;
        }
        return subItem;
      }) || null;
    return item;
  };

  const _handlePagesMenuItem = (item: AppMenuItem): AppMenuItem => {
    item.enabled = currentUserRoleId.value >= UserRoleEnum.User;
    return item;
  };

  const _handleLoginMenuItem = (item: AppMenuItem): AppMenuItem => {
    item.enabled =
      appStore.companyRowId ===
        import.meta.env.VITE_KLINIKUM_ERDING_COMPANY_ID &&
      currentUserIsKEDummy.value;
    return item;
  };

  const _handleCustomLinkMenuItem = (item: AppMenuItem): AppMenuItem => {
    item.enabled =
      companiesList.value[CompanyMenuEnum.BKG] === currentCompanyId.value;
    return item;
  };

  const _handleCustomPageMenuItem = (item: AppMenuItem): AppMenuItem => {
    item.enabled =
      companiesList.value[CompanyMenuEnum.BKG] === currentCompanyId.value ||
      companiesList.value[CompanyMenuEnum.AWO] === currentCompanyId.value;
    return item;
  };

  const _handleDefaultMenuItem = (item: AppMenuItem): AppMenuItem => {
    return item;
  };

  const _handleMobileOnlyMenuItem = (item: AppMenuItem): AppMenuItem => {
    item.enabled = isAnyMobile;
    return item;
  };

  const _handleOnlyForDevelopmentMenuItem = (
    item: AppMenuItem
  ): AppMenuItem => {
    item.enabled = import.meta.env.DEV;
    return item;
  };

  const _handleNotImplementedMenuItems = (item: AppMenuItem): null => {
    console.warn('Function not implemented: ' + item.name);
    return null;
  };

  /**
   * @description Returns filtered and sorted menu items with rules applied.
   * @example _applyGeneralMenuRules([AppMenuEnum.HomePage, AppMenuEnum.Feed]);
   */
  const _applyGeneralMenuRules = (enums: AppMenuEnum[]): AppMenuItem[] => {
    const menuItemHandlers: Record<
      AppMenuEnum,
      (item: AppMenuItem) => AppMenuItem | null
    > = {
      //NOTE: Specific cases
      [AppMenuEnum.HomePage]: _handleHomePageMenuItem,
      [AppMenuEnum.Search]: _handleSearchMenuItem,
      [AppMenuEnum.AiAssistant]: _handleAiAssistantMenuItem,
      [AppMenuEnum.Projects]: _handleProjectsMenuItem,
      [AppMenuEnum.Pages]: _handlePagesMenuItem,
      [AppMenuEnum.Login]: _handleLoginMenuItem,
      [AppMenuEnum.CustomLink]: _handleCustomLinkMenuItem,
      [AppMenuEnum.SecondCustomLink]: _handleCustomLinkMenuItem,
      [AppMenuEnum.CustomPage]: _handleCustomPageMenuItem,
      //NOTE: Default cases
      [AppMenuEnum.Feed]: _handleDefaultMenuItem,
      [AppMenuEnum.People]: _handleDefaultMenuItem,
      [AppMenuEnum.Groups]: _handleDefaultMenuItem,
      [AppMenuEnum.Docs]: _handleDefaultMenuItem,
      [AppMenuEnum.Calendar]: _handleDefaultMenuItem,
      [AppMenuEnum.Topics]: _handleDefaultMenuItem,
      [AppMenuEnum.Ideas]: _handleDefaultMenuItem,
      [AppMenuEnum.UsageRules]: _handleDefaultMenuItem,
      [AppMenuEnum.More]: _handleDefaultMenuItem,
      //TODO move from _handleProjectsMenuItem
      [AppMenuEnum.Tasks]: _handleDefaultMenuItem,
      //TODO move from _handleProjectsMenuItem
      [AppMenuEnum.Milestones]: _handleDefaultMenuItem,
      //TODO: [AppMenuEnum.ProjectsStatistics]
      [AppMenuEnum.Profile]: _handleDefaultMenuItem,
      [AppMenuEnum.Settings]: _handleDefaultMenuItem,
      [AppMenuEnum.Admin]: _handleDefaultMenuItem,
      [AppMenuEnum.ProjectsStatistics]: _handleDefaultMenuItem,
      [AppMenuEnum.Notifications]: _handleMobileOnlyMenuItem,
      [AppMenuEnum.Messenger]: _handleDefaultMenuItem,
      [AppMenuEnum.Networks]: _handleDefaultMenuItem,
      [AppMenuEnum.Wiki]: _handleDefaultMenuItem,
      [AppMenuEnum.File]: _handleDefaultMenuItem,
      [AppMenuEnum.Plus]: _handleMobileOnlyMenuItem,
      [AppMenuEnum.Campus]: _handleMobileOnlyMenuItem,
      //NOTE: Only for development cases
      [AppMenuEnum.Icons]: _handleOnlyForDevelopmentMenuItem,
      //NOTE: Not implemented cases
      [AppMenuEnum.AdminDesign]: _handleNotImplementedMenuItems,
      [AppMenuEnum.AdminNetworkSettings]: _handleNotImplementedMenuItems,
      [AppMenuEnum.AdminNetworkDomainList]: _handleNotImplementedMenuItems,
      [AppMenuEnum.AdminBranding]: _handleNotImplementedMenuItems,
      [AppMenuEnum.AdminMobileApps]: _handleNotImplementedMenuItems,
      [AppMenuEnum.AdminUsageRules]: _handleNotImplementedMenuItems,
      [AppMenuEnum.AdminPasswordSettings]: _handleNotImplementedMenuItems,
      [AppMenuEnum.AdminApplications]: _handleNotImplementedMenuItems,
      [AppMenuEnum.AdminStatistics]: _handleNotImplementedMenuItems,
      [AppMenuEnum.AdminBanner]: _handleNotImplementedMenuItems,
      [AppMenuEnum.AdminTags]: _handleNotImplementedMenuItems,
      [AppMenuEnum.AdminUserManagement]: _handleNotImplementedMenuItems,
      [AppMenuEnum.AdminRestorePost]: _handleNotImplementedMenuItems,
      [AppMenuEnum.AdminBadges]: _handleNotImplementedMenuItems,
      [AppMenuEnum.AdminEmailFooter]: _handleNotImplementedMenuItems,
    };

    const result: AppMenuItem[] = [];
    const finalMenuItems = _filterMenuItems(enums);
    finalMenuItems.map((menuItem) => {
      const handler = menuItemHandlers[menuItem.name];
      const item = handler(menuItem) ?? menuItem;
      item
        ? result.push(item)
        : console.warn('Menu item not found: ' + menuItem.name);
    });

    return result
      .filter((menuItem) => !menuItem.hidden && menuItem.enabled)
      .sort((a, b) => a.order - b.order);
  };

  const _handleBKGMenuItems = (name: AppMenuEnum): AppMenuItem | null => {
    switch (name) {
      case AppMenuEnum.HomePage: {
        return _createMenuItem({ order: 0, name: AppMenuEnum.HomePage });
      }
      case AppMenuEnum.Groups: {
        return _createMenuItem({ order: 1, name: AppMenuEnum.Groups });
      }
      case AppMenuEnum.CustomLink:
        return _createMenuItem({
          title: 'LinkedIn',
          href: 'https://www.linkedin.com/company/krankenhausgesellschaft/',
          order: 4,
          name: AppMenuEnum.CustomLink,
        });
      case AppMenuEnum.SecondCustomLink:
        return _createMenuItem({
          title: 'BKG-Presseportal',
          href: 'https://www.bkg-online.de/aktuelles/news',
          order: 5,
          name: AppMenuEnum.SecondCustomLink,
        });
      case AppMenuEnum.CustomPage:
        return _createMenuItem({
          title: 'Anleitung',
          link: {
            name: ROUTES_NAME.PAGE_BY_ID,
            params: { id: 7973 },
          },
          order: 6,
          name: AppMenuEnum.CustomPage,
        });
      default:
        return null;
    }
  };

  const _handleAWOMenuItems = (name: AppMenuEnum): AppMenuItem | null => {
    switch (name) {
      case AppMenuEnum.HomePage: {
        return _createMenuItem({ order: 0, name: AppMenuEnum.HomePage });
      }
      case AppMenuEnum.Feed: {
        return _createMenuItem({ order: 1, name: AppMenuEnum.Feed });
      }
      case AppMenuEnum.Messenger: {
        return _createMenuItem({ order: 2, name: AppMenuEnum.Messenger });
      }
      case AppMenuEnum.People: {
        return _createMenuItem({ order: 3, name: AppMenuEnum.People });
      }
      case AppMenuEnum.Groups: {
        return _createMenuItem({ order: 4, name: AppMenuEnum.Groups });
      }
      case AppMenuEnum.Search: {
        return _createMenuItem({ order: 5, name: AppMenuEnum.Search });
      }
      case AppMenuEnum.AiAssistant: {
        return _createMenuItem({ order: 6, name: AppMenuEnum.AiAssistant });
      }
      case AppMenuEnum.Projects: {
        return _createMenuItem({ order: 7, name: AppMenuEnum.Projects });
      }
      case AppMenuEnum.Docs: {
        return _createMenuItem({ order: 8, name: AppMenuEnum.Docs });
      }
      case AppMenuEnum.Calendar: {
        return _createMenuItem({ order: 9, name: AppMenuEnum.Calendar });
      }
      case AppMenuEnum.Pages: {
        return _createMenuItem({ order: 10, name: AppMenuEnum.Pages });
      }
      case AppMenuEnum.Topics: {
        return _createMenuItem({ order: 11, name: AppMenuEnum.Topics });
      }
      case AppMenuEnum.Ideas: {
        return _createMenuItem({ order: 12, name: AppMenuEnum.Ideas });
      }
      case AppMenuEnum.UsageRules: {
        return _createMenuItem({ order: 13, name: AppMenuEnum.UsageRules });
      }
      case AppMenuEnum.CustomPage:
        return _createMenuItem({
          title: 'FAQ',
          link: {
            name: ROUTES_NAME.WIKI_BY_ID,
            params: { id: 18437 },
          },
          order: 14,
          name: AppMenuEnum.CustomPage,
        });
      default:
        return null;
    }
  };

  /**
   * @description Returns filtered and sorted menu items with company-specific rules applied.
   * @example _applyCompanyMenuRules(CompanyMenuEnum.SHGT, [AppMenuEnum.HomePage, AppMenuEnum.Feed]);
   * @param key - The company key.
   * @param enums - The list of AppMenuEnum to apply rules to.
   * @returns {AppMenuItem[]} - The array of menu items with rules applied.
   */
  const _applyCompanyMenuRules = (
    key: CompanyMenuEnum,
    enums: AppMenuEnum[]
  ): AppMenuItem[] => {
    const companyRulesHandlers: Record<
      CompanyMenuEnum,
      (item: AppMenuEnum) => AppMenuItem | null
    > = {
      [CompanyMenuEnum.SHGT]: () => null,
      [CompanyMenuEnum.CAMPUS]: () => null,
      [CompanyMenuEnum.ANDERSEN]: () => null,
      [CompanyMenuEnum.VOEB]: () => null,
      [CompanyMenuEnum.BKG]: _handleBKGMenuItems,
      [CompanyMenuEnum.DLT]: () => null,
      [CompanyMenuEnum.AWO]: _handleAWOMenuItems,
    };

    const result: AppMenuItem[] = [];
    const finalMenuItems = _applyGeneralMenuRules(enums);
    finalMenuItems.map((menuItem) => {
      const handler = companyRulesHandlers[key];
      const item = handler(menuItem.name) ?? menuItem;
      item
        ? result.push(item)
        : console.warn('Menu item not found: ' + menuItem.name);
    });

    return result
      .filter((menuItem) => !menuItem.hidden && menuItem.enabled)
      .sort((a, b) => a.order - b.order);
  };

  const _handleDefaultCompanyMenuItems = (): AppMenuItem[] => {
    return _applyGeneralMenuRules([
      AppMenuEnum.HomePage,
      AppMenuEnum.Feed,
      AppMenuEnum.Messenger,
      // AppMenuEnum.Plus,
      // AppMenuEnum.Notifications,
      AppMenuEnum.People,
      AppMenuEnum.Groups,
      AppMenuEnum.Search,
      AppMenuEnum.AiAssistant,
      AppMenuEnum.Projects,
      AppMenuEnum.Docs,
      AppMenuEnum.Calendar,
      AppMenuEnum.Pages,
      AppMenuEnum.Topics,
      AppMenuEnum.Ideas,
      AppMenuEnum.UsageRules,
      AppMenuEnum.Login,
      AppMenuEnum.Icons,
      AppMenuEnum.Campus,
    ]);
  };

  /**
   * @description Get all the menu items, specific to the company or default ones.
   * @returns {<AppMenuItem>[]} The menu items specific to the company or default ones.
   */
  const _getMenuItems = (): AppMenuItem[] => {
    const companiesHandlers: Record<CompanyMenuEnum, AppMenuEnum[]> = {
      [CompanyMenuEnum.SHGT]: [
        AppMenuEnum.HomePage,
        AppMenuEnum.Messenger,
        AppMenuEnum.Groups,
        AppMenuEnum.AiAssistant,
        AppMenuEnum.Projects,
      ],
      [CompanyMenuEnum.CAMPUS]: [
        AppMenuEnum.HomePage,
        AppMenuEnum.Groups,
        AppMenuEnum.AiAssistant,
        AppMenuEnum.Projects,
        AppMenuEnum.Docs,
        AppMenuEnum.Calendar,
        AppMenuEnum.Topics,
        AppMenuEnum.Ideas,
        AppMenuEnum.UsageRules,
      ],
      [CompanyMenuEnum.ANDERSEN]: [
        AppMenuEnum.HomePage,
        AppMenuEnum.Feed,
        AppMenuEnum.People,
        AppMenuEnum.Groups,
        AppMenuEnum.AiAssistant,
        AppMenuEnum.Projects,
        AppMenuEnum.Docs,
        AppMenuEnum.Calendar,
        AppMenuEnum.Topics,
        AppMenuEnum.Ideas,
        AppMenuEnum.UsageRules,
      ],
      [CompanyMenuEnum.VOEB]: [
        AppMenuEnum.HomePage,
        AppMenuEnum.Groups,
        AppMenuEnum.AiAssistant,
        AppMenuEnum.Docs,
      ],
      [CompanyMenuEnum.BKG]: [
        AppMenuEnum.HomePage,
        AppMenuEnum.Groups,
        AppMenuEnum.CustomLink,
        AppMenuEnum.SecondCustomLink,
        AppMenuEnum.CustomPage,
      ],
      [CompanyMenuEnum.DLT]: [
        AppMenuEnum.HomePage,
        AppMenuEnum.Groups,
        AppMenuEnum.Search,
        AppMenuEnum.Topics,
        AppMenuEnum.UsageRules,
      ],
      [CompanyMenuEnum.AWO]: [
        AppMenuEnum.HomePage,
        AppMenuEnum.Feed,
        AppMenuEnum.Messenger,
        AppMenuEnum.People,
        AppMenuEnum.Groups,
        AppMenuEnum.Search,
        AppMenuEnum.AiAssistant,
        AppMenuEnum.Projects,
        AppMenuEnum.Docs,
        AppMenuEnum.Calendar,
        AppMenuEnum.Pages,
        AppMenuEnum.Topics,
        AppMenuEnum.Ideas,
        AppMenuEnum.UsageRules,
        AppMenuEnum.CustomPage,
      ],
    };

    const currentCompanyKey = Object.entries(companiesList.value).find(
      // for correct parsing of companiesList
      // eslint-disable-next-line
      ([key, value]) => value === currentCompanyId.value
    )?.[0] as CompanyMenuEnum;
    if (!currentCompanyKey) {
      console.warn(
        'Company not found: ' + currentCompanyId.value,
        ' - proceeding with default menu items'
      );
      return _handleDefaultCompanyMenuItems();
    }

    const enums = companiesHandlers[currentCompanyKey];
    if (!enums?.length) {
      console.error('Company menu items not found: ' + currentCompanyKey);
      return _handleDefaultCompanyMenuItems();
    }

    return _applyCompanyMenuRules(currentCompanyKey, enums);
  };

  /*
  const groupSelectedTab: ComputedRef<GroupPageTabEnum> = computed(
    () => useGroupsStore().selectedTab
  );
  const _getPlusTitle = (): string => {
    switch (router.currentRoute.value.name) {
      case ROUTES_NAME.FEED:
      case ROUTES_NAME.TOPIC_BY_ID:
      case ROUTES_NAME.IDEAS:
        return t('appBottomMenu.post');

      case ROUTES_NAME.PROJECT_BY_ID:
        return t('appBottomMenu.create');

      case ROUTES_NAME.PROJECTS:
        return t('appBottomMenu.create');

      case ROUTES_NAME.MILESTONES:
        return t('appBottomMenu.create');

      case ROUTES_NAME.PAGES:
        return t('appBottomMenu.create');

      case ROUTES_NAME.GROUP_DASHBOARD:
        return t('appBottomMenu.info');

      case ROUTES_NAME.PAGE_EDIT:
        return t('appBottomMenu.info');

      case ROUTES_NAME.PAGE_BY_ID:
        return t('appBottomMenu.edit');

      case ROUTES_NAME.CALENDAR:
        return t('appBottomMenu.create');

      case ROUTES_NAME.GROUP_BY_ID: {
        switch (groupSelectedTab.value) {
          case GroupPageTabEnum.Dashboard:
            return t('appBottomMenu.edit');

          case GroupPageTabEnum.Feed:
            return t('appBottomMenu.post');

          case GroupPageTabEnum.Files:
            return t('appBottomMenu.upload');
        }
      }

      case ROUTES_NAME.DOCS:
        return t('appBottomMenu.upload');
    }

    //TODO: Add default case
    return router.currentRoute.value.name?.toString() ?? '';
  };
  */

  //* Public
  const getHeaderMenu = (): AppMenuItem[] => {
    const items = _getMenuItems();

    if (items.length <= maxItemsInHeaderMenu.value) {
      return items;
    } else {
      const splicedItems = items.splice(0, maxItemsInHeaderMenu.value - 1);
      return [
        ...splicedItems,
        {
          order: splicedItems.length,
          name: AppMenuEnum.More,
          title: t('appMenu.more'),
          icon: AppIconsEnum.Applications,
          enabled: true,
          link: null,
          submenu: items,
          hidden: false,
        },
      ];
    }
  };

  const getRightMenu = (): AppMenuItem[] => {
    const data = [
      {
        order: 0,
        name: AppMenuEnum.Profile,
        title: t('account.title'),
        icon: AppIconsEnum.Profile,
        enabled: true,
        submenu: null,
        hidden: false,
        link: {
          name: ROUTES_NAME.USER_BY_ID,
          params: { id: currentUserId.value },
        },
      },

      {
        order: 1,
        name: AppMenuEnum.Settings,
        title: t('appMenu.settings'),
        icon: AppIconsEnum.Settings,
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.SETTINGS },
      },

      {
        order: 2,
        name: AppMenuEnum.Admin,
        title: t('appMenu.administration'),
        icon: AppIconsEnum.Admin,
        enabled: currentUserRoleId.value >= UserRoleEnum.Moderator,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.ADMIN_DESIGN },
      },
    ];

    return data
      .filter((n) => !n.hidden && n.enabled)
      .sort((a, b) => a.order - b.order);
  };

  const getFooterMenu = (): AppMenuItem[] => {
    const items = _getMenuItems().filter(
      (i) => i.name !== AppMenuEnum.Plus && i.name !== AppMenuEnum.Notifications
    );

    /* NOTE: Splicing the items to fit the max items in the bottom menu, according to the screen width. */
    const splicedItems = items.splice(
      0,
      !isMDWidth.value
        ? maxItemsInBottomMenu - 3
        : maxItemsInHeaderMenu.value - 1
    );

    const lasItem =
      items.length > 1
        ? {
            order: splicedItems.length,
            name: AppMenuEnum.More,
            title: t('appMenu.more'),
            icon: AppIconsEnum.Applications,
            enabled: items.length > 0,
            link: null,
            submenu: items,
            hidden: false,
          }
        : items.length > 0
          ? items[0]
          : null;

    const resultItems = [
      ...splicedItems,
      {
        order: 2,
        name: AppMenuEnum.Plus,
        title: t('appBottomMenu.post'),
        icon: AppIconsEnum.PlusSquareO,
        enabled: !isMDWidth.value,
        submenu: null,
        hidden: false,
      },
      {
        order: 3,
        name: AppMenuEnum.Notifications,
        title: t('appMenu.notifications'),
        icon: AppIconsEnum.Notifications,
        enabled: !isMDWidth.value,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.NOTIFICATIONS },
      },
    ];

    if (lasItem) {
      resultItems.push(lasItem);
    }

    return resultItems.filter((n) => !n.hidden && n.enabled);
  };

  const isCampusEnabled = (): boolean => {
    const list: ComputedRef<{ [key: string]: string }> = computed(() => {
      if (!import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_CAMPUS) {
        console.warn(
          'The env variable VITE_COMPANIES_LIST_WITH_DISABLED_CAMPUS is not defined'
        );
        return {};
      }

      try {
        return JSON.parse(
          import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_CAMPUS
        );
      } catch (error) {
        console.error(
          'Error while parsing the VITE_COMPANIES_LIST_WITH_DISABLED_CAMPUS env variable:',
          error
        );
        return {};
      }
    });

    if (Object.values(list.value).includes(currentCompanyId.value))
      return false;

    return true;
  };

  const isQuickSearchEnabled = (): boolean => {
    const list: ComputedRef<{ [key: string]: string }> = computed(() => {
      if (!import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_QUICK_SEARCH) {
        console.warn(
          'The env variable VITE_COMPANIES_LIST_WITH_DISABLED_QUICK_SEARCH is not defined'
        );
        return {};
      }

      try {
        return JSON.parse(
          import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_QUICK_SEARCH
        );
      } catch (error) {
        console.error(
          'Error while parsing the VITE_COMPANIES_LIST_WITH_DISABLED_QUICK_SEARCH env variable:',
          error
        );
        return {};
      }
    });

    if (Object.values(list.value).includes(currentCompanyId.value))
      return false;

    return true;
  };

  const getCampusLink = (): string => {
    const list: ComputedRef<{
      [key: string]: { id: string; url: string };
    }> = computed(() => {
      if (!import.meta.env.VITE_CAMPUS_LINK_COMPANIES_LIST) {
        console.warn(
          'The env variable VITE_CAMPUS_LINK_COMPANIES_LIST is not defined'
        );
        return {};
      }

      try {
        return JSON.parse(import.meta.env.VITE_CAMPUS_LINK_COMPANIES_LIST);
      } catch (error) {
        console.error(
          'Error while parsing the VITE_CAMPUS_LINK_COMPANIES_LIST env variable:',
          error
        );
        return {};
      }
    });

    for (const key in list.value) {
      if (list.value[key].id === currentCompanyId.value) {
        return list.value[key].url;
      }
    }

    return import.meta.env.VITE_CAMPUS_LINK; // Default URL
  };

  const openCampusModal = async (): Promise<void> => {
    if (currentCompanyId.value === '0cb21e25-a5f3-4e30-8366-6c5787718a12') {
      Browser.open({ url: getCampusLink() });
    } else {
      await componentCampusModal();
    }
  };

  const checkForActive = (element: any, route: Route): boolean => {
    return (
      (!route.params?.id && element.link?.name === route.name) ||
      (!!route.params?.id &&
        element.link?.name === route.name &&
        element.link?.params.id === +route.params.id)
    );
  };

  const validateCustomLink = async (href: string): Promise<boolean> => {
    const rules = {
      href: {
        extentedUrl,
      },
    };
    const state = reactive<{ href: string }>({
      href: href,
    });
    const v$ = useVuelidate(rules, state);
    return await v$.value.$validate();
  };

  return {
    companiesList,
    getHeaderMenu,
    getRightMenu,
    getFooterMenu,
    isCampusEnabled,
    isQuickSearchEnabled,
    getCampusLink,
    openCampusModal,
    checkForActive,
    validateCustomLink,
  };
};
